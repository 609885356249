import { useLayoutEffect } from "react";
import "./Styles/global.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "react-alice-carousel/lib/scss/alice-carousel.scss";

import AnimatedRoutes from "AnimatedRoutes";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://68ee484010354cb98ef3a76e841a32d6@o1397134.ingest.sentry.io/6722168",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  ;
}


const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <AnimatedRoutes />
        </Wrapper>
      </BrowserRouter>
    </>
  );
}

export default App;
