import Skeleton from "react-loading-skeleton";

export default function CourseSkeleton() {
  return (
    <div className="">
      <section className="sm:mx-[calc(50%-50vw)] bg-dark-blue ">
        <div className="lg:container mx-auto">
          <div className="lg:grid grid-cols-12 h-fit lg:h-[420px]">
            <div className="lg:col-span-4 lg:order-last lg:my-10 lg:mr-2 flex items-center justify-start">
              <div className="hidden lg:block w-full h-full">
                <Skeleton height="100%" width="100%" />
              </div>
              <div className="lg:hidden flex justify-center w-screen h-56">
                <div className="w-3/4 h-full">
                  <Skeleton height="100%" width="100%" />
                </div>
              </div>
            </div>
            <div className="lg:col-span-1 md:order-2"></div>

            <div
              className=" py-5 px-3 ml-2 h-full lg:col-span-7 md:flex lg:justify-center 
                        lg:flex-col lg:items-start md:flex-col md:items-center"
            >
              <div>
                <h1 className=" font-bold text-[36px] leading-[48px] text-white  mb-4">
                  <Skeleton count={2} width="500px" height="30px" />
                </h1>

                <Skeleton width="130px" height="20px" />
                <div className="mt-5 flex">
                  <div className="mr-4">
                    <Skeleton width="110px" height="35px" borderRadius={50} />
                  </div>

                  <Skeleton width="110px" height="35px" borderRadius={50} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lg:grid lg:grid-cols-12 mx-2 ">
        <div className="col-span-12 lg:col-span-4 lg:order-last flex lg:justify-end pt-9">
          <div className="py-3 lg:h-max mb-2 lg:mb-0 border-2 border-solid border-[#D9D9D9] rounded-md w-full lg:w-[330px] ">
            <h2 className=" font-[600] pt-2 ml-4 pb-3 px-2">The Summary</h2>
            <div className="border-b-2 border-solid border-[#D9D9D9]"></div>
            <div className=" space-y-2 ml-4 px-2">
              <p className="font-[600] text-[#000E24] mt-2">
                <Skeleton />
              </p>

              <p className="font-[600] text-[#000E24]">
                <Skeleton />
              </p>

              <p className="font-[600] text-[#000E24]">
                <Skeleton />
              </p>

              <p className="font-[600] text-[#000E24]">
                <Skeleton />
              </p>

              <p className="font-[600] text-[#000E24]">
                <Skeleton />
              </p>

              <p className="font-[600] text-[#000E24]">
                <Skeleton />
              </p>

              <p className="font-[600] text-[#000E24]">
                <Skeleton />
              </p>

              <p className="font-[600] text-[#000E24] pb-1">
                <Skeleton />
              </p>
            </div>
          </div>
        </div>

        <section className="lg:col-span-8 pt-6 lg:pt-9">
          <div>
            <h3 className="text-[20px] font-[600] leading-5 mb-6">
              Course Description
            </h3>
            <div className="font-[400] text-[#666666] lg:pr-3 xl:pr-0" />
            <Skeleton count={6} />
          </div>
          <section className=" ">
            <h3 className="text-[20px] font-[600] my-6 ">Course Curriculum</h3>
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" />
            <Skeleton height="40px" />
          </section>
          <section className="">
            <h3 className="text-[20px] font-[600] my-6 ">Course Certificate</h3>
            <Skeleton height="200px" width="300px" />
          </section>
          <section className="">
            <h3 className="text-[20px] font-[600] my-6 ">Course Credits</h3>
            <Skeleton count={4} />
          </section>
        </section>
      </section>
    </div>
  );
}
