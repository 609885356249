import { lazy, Suspense } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { RoutePaths } from "Api/RoutePaths";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import AuthRedirect from "Components/Layout/AuthRedirect";
import HomePage from "Pages/HomePage/HomePage";
import SuspenseLoading from "Components/SuspenseLoading";
import { useAppSelector } from "Redux/store";
import { ToastComponent } from "Components/ToastComponent";

const LoginPage = lazy(() => import("./Pages/Authentication/SignIn"));
const Signup = lazy(() => import("Pages/Authentication/SignUpPage"));
const OtpVerification = lazy(
  () => import("Pages/Authentication/OtpVerification")
);
const Notification = lazy(() => import("./Pages/Notification"));
const ByteAudioOrVideo = lazy(() => import("Pages/ByteAudioOrVideo"));
const Course = lazy(() => import("Pages/Course"));
const VideoCourse = lazy(() => import("Pages/videoCourse"));
const UserInformation1 = lazy(
  () => import("Pages/Authentication/UserInformation1")
);
const UserInformation = lazy(
  () => import("Pages/Authentication/UserInformation")
);
const Cart = lazy(() => import("Pages/Cart"));
const FetchMyCourses = lazy(() => import("Pages/fetchMyCourses"));
const ViewallCourses = lazy(() => import("Pages/ViewallCourses"));
const PhoneNumber = lazy(() => import("Pages/Authentication/phoneNumber"));
const VerifyEmail = lazy(() => import("Pages/Authentication/VerifyEmail"));
const Redirect = lazy(() => import("Pages/Redirect"));
const RequestFailed = lazy(() => import("Pages/RequestFailed"));
const NoMatch = lazy(() => import("Pages/NoMatch"));
const ViewAllBytes = lazy(() => import("Pages/ViewAllBytes"));
const SharedRoute = lazy(() => import("./Components/Layout/Layout"));
const EmailPassword = lazy(() => import("Pages/Authentication/EmailPassword"));
const SetNewPassword = lazy(
  () => import("Pages/Authentication/SetNewPassword")
);
const User = lazy(() => import("Pages/User"));
const ProfileDetails = lazy(() => import("Pages/User/ProfileDetailsCard"));
const EditDetails = lazy(() => import("Pages/User/EditDetailsCard"));
const SecuritySettings = lazy(() => import("Pages/User/SecuritySettings"));
const PurchaseHistory = lazy(() => import("Pages/User/PurchaseHistory"));

export default function AnimatedRoutes() {
  const userStatus = useAppSelector((state) => state.auth.isUserSignedIn);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AnimatePresence>
      <Suspense fallback={SuspenseLoading(location.pathname)}>
        <Routes location={location} key={location.pathname}>
          <Route
            path={RoutePaths.root}
            element={
              <SharedRoute
                backgroundColor="bg-dark-blue"
                footerBackgroundColor="bg-[#F6F9FC]"
              >
                <HomePage />
              </SharedRoute>
            }
          />

          <Route
            path={RoutePaths.signin}
            element={
              <SharedRoute
                backgroundColor="bg-dark-blue"
                footerBackgroundColor="bg-[#F6F9FC]"
              >
                <AuthRedirect>
                  <LoginPage />
                </AuthRedirect>
              </SharedRoute>
            }
          />
          <Route path={RoutePaths.signUp}>
            <Route
              index
              element={
                <SharedRoute
                  backgroundColor="bg-dark-blue"
                  footerBackgroundColor="bg-[#F6F9FC]"
                >
                  <AuthRedirect>
                    <Signup />
                  </AuthRedirect>
                </SharedRoute>
              }
            />

            <Route
              path={RoutePaths.emailPassword}
              element={
                <SharedRoute
                  backgroundColor="bg-dark-blue"
                  footerBackgroundColor="bg-[#F6F9FC]"
                >
                  <AuthRedirect>
                    <EmailPassword />
                  </AuthRedirect>
                </SharedRoute>
              }
            />
            <Route
              path={RoutePaths.phoneNumber}
              element={
                <SharedRoute
                  backgroundColor="bg-dark-blue"
                  footerBackgroundColor="bg-[#F6F9FC]"
                >
                  <AuthRedirect>
                    <PhoneNumber />
                  </AuthRedirect>
                </SharedRoute>
              }
            />
            <Route
              path={`${RoutePaths.otpVerification}/:email`}
              element={
                <SharedRoute
                  backgroundColor="bg-dark-blue"
                  footerBackgroundColor="bg-[#F6F9FC]"
                >
                  <AuthRedirect>
                    <OtpVerification />
                  </AuthRedirect>
                </SharedRoute>
              }
            />
            <Route
              path={RoutePaths.information}
              element={
                <SharedRoute
                  backgroundColor="bg-dark-blue"
                  footerBackgroundColor="bg-[#F6F9FC]"
                >
                  {/* <AuthRedirect> */}
                  <UserInformation />
                  {/* </AuthRedirect> */}
                </SharedRoute>
              }
            />
            <Route
              path={RoutePaths.information1}
              element={
                <SharedRoute
                  backgroundColor="bg-dark-blue"
                  footerBackgroundColor="bg-[#F6F9FC]"
                >
                  {/* <AuthRedirect> */}
                  <UserInformation1 />
                  {/* </AuthRedirect> */}
                </SharedRoute>
              }
            />
          </Route>
          <Route
            path={RoutePaths.verifyemail}
            element={
              <SharedRoute
                backgroundColor="bg-dark-blue"
                footerBackgroundColor="bg-[#F6F9FC]"
              >
                <AuthRedirect>
                  <VerifyEmail />
                </AuthRedirect>
              </SharedRoute>
            }
          />
          <Route
            path={RoutePaths.setnewpassword}
            element={
              <SharedRoute
                backgroundColor="bg-dark-blue"
                footerBackgroundColor="bg-[#F6F9FC]"
              >
                <AuthRedirect>
                  <SetNewPassword />
                </AuthRedirect>
              </SharedRoute>
            }
          />
          <Route
            path={RoutePaths.redirect}
            element={
              <SharedRoute backgroundColor="bg-dark-blue">
                <Redirect />
              </SharedRoute>
            }
          />

          <Route
            path={"/LearningBytes"}
            element={
              <SharedRoute>
                <ByteAudioOrVideo />
              </SharedRoute>
            }
          />

          <Route
            path={"/viewallcourses"}
            element={
              <SharedRoute footerBackgroundColor="bg-[#F6F9FC]">
                <ViewallCourses />
              </SharedRoute>
            }
          />

          <Route
            path={"/viewallbytes"}
            element={
              <SharedRoute footerBackgroundColor="bg-[#F6F9FC]">
                <ViewAllBytes />
              </SharedRoute>
            }
          />

          <Route
            path={RoutePaths.myCourses}
            element={
              <SharedRoute
                backgroundColor="bg-dark-blue"
                footerBackgroundColor="bg-[#F6F9FC]"
              >
                {userStatus === false ? (
                  <ToastComponent
                    message="You should be logged in to view content"
                    toastType="yellow"
                    onClick={() => navigate("/")}
                    actionButton={true}
                    actionButtonText="Log In"
                    actionButtonOnClick={(e) => {
                      e.stopPropagation();
                      console.log("i am clicked");
                      navigate("/signIn");
                    }}
                  />
                ) : (
                  <FetchMyCourses />
                )}
              </SharedRoute>
            }
          />

          <Route
            path={RoutePaths.notification}
            element={
              <SharedRoute backgroundColor="bg-dark-blue">
                <Notification />
              </SharedRoute>
            }
          />

          <Route
            path={RoutePaths.course}
            element={
              <SharedRoute backgroundColor="bg-dark-blue">
                <Course />
              </SharedRoute>
            }
          />

          <Route
            path={"/videocourse"}
            element={
              <SharedRoute hideNavBar={true}>
                <VideoCourse />
              </SharedRoute>
            }
          />

          <Route
            path={RoutePaths.cart}
            element={
              <SharedRoute backgroundColor="bg-dark-blue">
                <Cart />
              </SharedRoute>
            }
          />
          {userStatus && (
            <Route
              path={RoutePaths.user}
              element={
                <SharedRoute
                  backgroundColor="bg-dark-blue"
                  footerBackgroundColor="bg-[#F6F9FC]"
                >
                  <User />
                </SharedRoute>
              }
            >
              <Route index element={<ProfileDetails />} />
              <Route path={RoutePaths.edit} element={<EditDetails />} />
              <Route
                path={RoutePaths.security}
                element={<SecuritySettings />}
              />
              <Route
                path={RoutePaths.purchasehistory}
                element={<PurchaseHistory />}
              />
            </Route>
          )}

          <Route
            path="*"
            element={
              <SharedRoute
                backgroundColor="bg-dark-blue"
                footerBackgroundColor="bg-[#fff]"
              >
                {userStatus === false ? (
                  <ToastComponent
                    message="You should be logged in to view content"
                    toastType="yellow"
                    onClick={() => navigate("/")}
                    actionButton={true}
                    actionButtonText="Log In"
                    actionButtonOnClick={(e) => {
                      e.stopPropagation();
                      console.log("i am clicked");
                      navigate("/signIn");
                    }}
                  />
                ) : (
                  <NoMatch />
                )}
              </SharedRoute>
            }
          />
          <Route
            path={"/requestfailed"}
            element={
              <SharedRoute
                backgroundColor="bg-dark-blue"
                footerBackgroundColor="bg-[#fff]"
              >
                <RequestFailed />
              </SharedRoute>
            }
          />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
}
