export enum RoutePaths {
  // root
  root = "/",
  notFound = "*",

  // Auth Routes
  signUp = "/signup/",
  signin = "/signin/",
  otpVerification = "otpverification",
  information = "information",
  information1 = "information1",
  emailPassword = "emailPassword",
  phoneNumber = "phoneNumber",
  redirect = "redirect",
  verifyemail = "verifyemail",
  setnewpassword = "setnewpassword",

  //User Routes
  user = "/user/",
  edit = "editProfile",
  security = "security",
  purchasehistory = "purchasehistory",
  notification = "/notification",
  cart = "/cart",
  myCourses = "/mycourses",
  course = "/course",
  learningBytes = "/LearningBytes",
  // App Routes
  home = "home/",
}
