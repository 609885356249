import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { authSlice } from "./Reducers/authSlice";
import { fetchDataSlice } from "./Reducers/fetchDataSlice";
import { myLearningSlice } from "./Reducers/myLearningSlice";
import { videoCompletionSlice } from "./Reducers/videoTrack";
import { cartSlice } from "./Reducers/Cart";
import type { TypedUseSelectorHook } from "react-redux";
import { infoSlice } from "./Reducers/userInfo";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import purchaseHistory from "./Reducers/purhaseHistory";
import { pathAndSlugPersist } from "./Reducers/pathAndSlugPersist";
import { suggestionSlice } from "./Reducers/suggestionsSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedPathAndSlugPersistReducer = persistReducer(
  persistConfig,
  pathAndSlugPersist.reducer
);

export const store = configureStore({
  reducer: {
    pathAndSlugPersist: persistedPathAndSlugPersistReducer,
    auth: authSlice.reducer,
    userInfo: infoSlice.reducer,
    homePage: fetchDataSlice.reducer,
    cart: cartSlice.reducer,
    suggestions: suggestionSlice.reducer,
    myLearning: myLearningSlice.reducer,
    videoTracking: videoCompletionSlice.reducer,
    purchaseHistory,
  },
  devTools: { trace: true, traceLimit: 25 },
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
