import { RoutePaths } from "Api/RoutePaths";
import { LoadingSpinner } from "./LoadingSpinner";
import HomePageSkeleton from "./Skeletons/HomePageSkeleton";
import CourseSkeleton from "./Skeletons/CourseSkeleton";

export default function SuspenseLoading(pathName: string) {
  switch (pathName) {
    case "/course":
      return (
        <div className="mt-[60px]">
          <CourseSkeleton />
        </div>
      );
    case "/":
      return <HomePageSkeleton />;

    default:
      return <LoadingSpinner></LoadingSpinner>;
  }
}
