export enum status {
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
  failed = "failed",
  askConfirmation = "askConfirmation",
  
}

export enum user{
  profilePlaceholder="https://su.learnsure.ai/static/media/profile-placeholder.1cfb2a52.png"
}