import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { status } from "enums/enums";
import { Data } from "types/data";

interface SuggestionData {
  category: string;
  page: number | undefined;
}

interface SuggestionState {
  suggestions: Data[];
  status: string;
  error: any;
}

const initialState: SuggestionState = {
  suggestions: [],
  status: status.idle,
  error: null,
};

export const fetchSuggestions = createAsyncThunk(
  "suggestions/fetchSuggestions",
  async ({ category, page }: SuggestionData) => {
    try {
      const courses = await API.get("api", `courses/`, {
        queryStringParameters: {
          category,
          page,
        },
      });

      return courses.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const suggestionSlice = createSlice({
  name: "suggestions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuggestions.pending, (state) => {
      state.status = status.loading;
    });

    builder.addCase(fetchSuggestions.fulfilled, (state, action) => {
      state.status = status.succeeded;
      state.suggestions = action.payload;
    });

    builder.addCase(fetchSuggestions.rejected, (state, action) => {
      state.status = status.failed;
      state.error = action.error.message;
    });
  },
});

export default suggestionSlice.reducer;
