import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  path: null,
  slug: null,
};

export const pathAndSlugPersist = createSlice({
  name: "pathAndSlugPersist",
  initialState,
  reducers: {
    setPath: (state, action: PayloadAction<any>) => {
      state.path = action.payload;
    },
    setSlug: (state, action: PayloadAction<any>) => {
      state.slug = action.payload;
    },
    clearPath: (state) => {
      state.path = null;
      state.slug = null;
    },
  },
});

export const { setPath, setSlug, clearPath } = pathAndSlugPersist.actions;
export default pathAndSlugPersist.reducer;
