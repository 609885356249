import React, { useState, useEffect, ReactElement } from "react";

interface props {
  useref?: React.MutableRefObject<null>;
  buttonStyle?: string;
  buttonText: string | ReactElement;
  disabled?: boolean | string;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: any;
  error?: string;
}

function button(
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
  buttonText: string | ReactElement,
  buttonStyle?: string,
  icon?: any,
  useref?: React.MutableRefObject<null>,
  error?: string
) {
  return (
    <>
      <p
        className={`text-sm font-medium text-red-500 ml-2 ${
          error ? "" : "hidden"
        }`}
      >
        {error ? error : ""}
      </p>
      <button
        ref={useref}
        type="button"
        className={buttonStyle}
        onClick={onClick}
      >
        <div className="flex justify-center items-center">
          <div>{buttonText}</div>
          <div className="ml-[0.1em]">{icon && icon}</div>
        </div>
      </button>
    </>
  );
}

function disabledButton(
  buttonText: string | ReactElement | undefined,
  isLoading: boolean | undefined,
  buttonStyle: string | undefined,
  icon: any
) {
  return (
    <>
      <button type="button" disabled className={buttonStyle} onClick={() => {}}>
        {isLoading && (
          <div className="flex items-center justify-center space-x-2 mr-2 cursor-progress">
            <div
              className="spinner-border animate-spin inline-block w-4 h-4 border-1 rounded-full"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="flex justify-center items-center">
          <div>{buttonText}</div>
          <div>{icon && icon}</div>
        </div>
      </button>
    </>
  );
}

export default function ButtonComponent({
  useref,
  buttonText,
  disabled = true,
  onClick,
  buttonStyle,
  isLoading,
  icon,
  error,
}: props) {
  const [style, setStyle] = useState("");

  //checks buttonstyle prop and conditionally adds styles to state and its then used in components
  useEffect(() => {
    if (buttonStyle === "darkBlue" && disabled === false) {
      setStyle(
        "flex btn bg-dark-blue text-white whitespace-nowrap opacity-50 cursor-not-allowed"
      );
    } else if (buttonStyle === "darkBlue") {
      setStyle("flex btn bg-dark-blue text-white whitespace-nowrap ");
    } else if (!buttonStyle && disabled === false) {
      setStyle("flex btn bg-indigo-300 text-white  whitespace-nowrap");
    } else if (buttonStyle === "yellow") {
      setStyle(
        "flex btn bg-[#a17902] text-white hover:opacity-75 whitespace-nowrap"
      );
    }
    else if (buttonStyle === "lightBlue") {
      setStyle(
        "flex btn bg-[#E6EEFF] text-[#2F60F5] hover:opacity-75 px-8 rounded-xl whitespace-nowrap"
      );
    }  else if (buttonStyle) {
      setStyle(buttonStyle);
    } else {
      setStyle(
        "flex btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
      );
    }
  }, [buttonStyle, disabled]);

  return disabled
    ? button(onClick, buttonText, style, icon, useref, error)
    : disabledButton(buttonText, isLoading, style, icon);
}
