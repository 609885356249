import { RoutePaths } from "Api/RoutePaths";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Redux/store";

export default function AuthRedirect({ children }: { children: JSX.Element }) {
  const userStatus = useAppSelector((state) => state.auth.isUserSignedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (userStatus===true) {
      navigate(RoutePaths.root);
    }
  }, [navigate, userStatus])

  return <>{children}</>;
}
