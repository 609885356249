import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { createSearchParams, useNavigate } from "react-router-dom";

import hover from "../../Styles/Partials/hover-animation.module.scss";
import { status } from "enums/enums";
import TruncateMarkup from "react-truncate-markup";

import { useAppDispatch, useAppSelector } from "Redux/store";

import { ToastComponent } from "Components/ToastComponent";
import { setPath } from "Redux/Reducers/pathAndSlugPersist";
import Banner from "../../Assets/Images/Banner.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function HomePageByte() {
  const navigate = useNavigate();
  const isUserSignedIn = useAppSelector((state) => state.auth.isUserSignedIn);
  const [currentStatus, setCurrentStatus] = useState(status.idle);

  const dispatch = useAppDispatch();

  const bytesData = useAppSelector((state) => state.homePage.bytes);

  const homePageStatus = useAppSelector((state) => state.homePage.status);

  function handleByteMeButtonClick() {
    navigate({
      pathname: "/LearningBytes",
      search: createSearchParams({
        slug: bytesData ? bytesData.slug : "Learnsure AI",
      }).toString(),
    });
  }

  const pageContentLoading = () => {
    return (
      <div className={hover.hoverobject}>
        <div className="md:mt-5 lg:mt-10 md:grid md:grid-cols-4 bg-white md:rounded-xl h-[332px]">
          {/* ------ right ------- */}
          <div className="px-6 py-4 md:py-0 md:col-span-2 h-full md:rounded-md flex flex-col items-start justify-center ">
            <Skeleton width={100} />

            <div className="text-[22px] font-[600] leading-[28px] mb-[15px] font-poppins">
              <Skeleton count={2} width={300} />
            </div>

            <div className="text-left text-[14px] text-[#666666] mb-[15px] font-inter font-[400]">
              <div className="-m-[8px] text-left"></div>

              <Skeleton count={3} width={300} height={10} />
            </div>

            <div className="flex self-start items-center ">
              <Skeleton width={100} height={40} />

              <div className="mt-1 ml-1">
                <Skeleton width={40} height={10} />
              </div>
            </div>
          </div>
          {/* ------ left ------- */}
          <div className="md:col-span-2 leading-[1]">
            <Skeleton height="332px" width="100" />
          </div>
        </div>
      </div>
    );
  };

  const pageContentSucceeded = () => {
    return (
      <div className={hover.hoverobject}>
        <div className="md:mt-5 lg:mt-10 md:grid md:grid-cols-12 bg-white md:rounded-xl h-[332px]">
          {/* ------ left ------- */}
          <div className="px-6 py-4 md:py-0 md:col-span-7 h-full md:rounded-md flex flex-col items-start justify-between ">
            {bytesData?.title ? (
              <div
                className="mt-4 flex self-start bg-[#ECF0F9]
                rounded-[20px] text-light-blue
                cursor-pointer font-poppins text-[14px]
                font-[500] justify-center mb-[14px]
                py-[5px] px-3"
              >
                Learning Byte
              </div>
            ) : (
              <Skeleton width={100} />
            )}

            <div>
              <div className="text-[24px] font-semibold text-dark-blue leading-[28px] mb-6 font-poppins">
                {bytesData?.title ? (
                  <TruncateMarkup lines={2}>
                    <div className="">{bytesData.title}</div>
                  </TruncateMarkup>
                ) : (
                  <Skeleton count={2} width={300} />
                )}
              </div>
              <div className="text-left text-[18px] text-dark-blue mb-[15px] font-[400] font-poppins">
                <div className="-m-[8px] text-left"></div>
                {bytesData?.description ? (
                  <TruncateMarkup lines={3}>
                    <div>{bytesData.description}</div>
                  </TruncateMarkup>
                ) : (
                  <Skeleton count={3} width={300} height={10} />
                )}
              </div>
            </div>

            <div className="flex self-start items-center mb-4">
              {bytesData?.title ? (
                <div
                  className="bg-[#001436] rounded-[30px] text-white cursor-pointer text-center
                  font-poppins text-[16px] font-[600] py-[8px] px-[15px] w-[112px]"
                  onClick={() => {
                    if (!isUserSignedIn) {
                      setCurrentStatus(status.askConfirmation);
                      return;
                    }
                    handleByteMeButtonClick();
                  }}
                >
                  Byte Me!
                </div>
              ) : (
                <Skeleton width={100} height={40} />
              )}

              {bytesData && (
                <div className="ml-[10px] text-dark-blue font-inter font-[700] text-[14px]">
                  {bytesData.time || <Skeleton width={40} height={10} />}
                </div>
              )}
            </div>
          </div>

          {/* ------ right ------- */}
          <div className="md:col-span-5 py-14 pr-8">
            {bytesData?.thumbnailResource?.metaData?.compressed ? (
              <img
                src={bytesData.thumbnailResource?.metaData?.compressed}
                alt="byte img"
                className="h-full object-fill md:rounded-xl"
              />
            ) : (
              <Skeleton height="185px" width="100" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const pageContentByteDataNull = () => {
    return (
      <div className={hover.hoverobject}>
        <div className="md:mt-5 lg:mt-10 md:grid md:grid-cols-12 bg-white md:rounded-xl  h-[332px]">
          {/* ------ left ------- */}
          <div className="px-6 py-4 md:py-0 md:col-span-7 h-full md:rounded-md flex flex-col items-start justify-between  ">
            <div
              className=" mt-4 flex self-start bg-[#ECF0F9]
              rounded-[20px] text-light-blue
              cursor-pointer font-poppins text-[14px]
              font-[500] justify-center mb-[14px]
              py-[5px] px-3"
            >
              Learning Byte
            </div>

            <div className="text-[24px] font-semibold text-dark-blue leading-[28px] font-poppins">
              <TruncateMarkup lines={2}>
                <div className="">
                  {"How to manage relationships with online customers?"}
                </div>
              </TruncateMarkup>
            </div>

            <div className="text-left text-[18px] text-dark-blue mb-[15px] font-[400] font-poppins">
              <div className="-m-[8px] text-left"></div>

              <TruncateMarkup lines={3}>
                <div className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  aliquet sodales dictum sagittis quisque. At tellus ut
                  scelerisque vitae risus dignissim enim
                </div>
              </TruncateMarkup>
            </div>

            <div className="flex self-start items-center mb-4 ">
              <div
                className="bg-[#001436] rounded-[30px] text-white cursor-pointer text-center
                font-poppins text-[16px] font-[600] py-[8px] px-[15px] w-[112px]"
                onClick={() => {
                  if (!isUserSignedIn) {
                    setCurrentStatus(status.askConfirmation);
                    return;
                  }
                  handleByteMeButtonClick();
                }}
              >
                Byte Me!
              </div>

              <div className="ml-[10px] text-dark-blue font-inter font-[700] text-[14px]">
                4 min
              </div>
            </div>
          </div>
          {/* ------ right ------- */}
          <div className="md:col-span-5 py-14 pr-8">
            <img
              src={Banner}
              alt="byte img"
              className="max-h-[220px] w-full object-cover md:rounded-xl"
            />
          </div>
        </div>
      </div>
    );
  };

  function calculatePageContent() {
    if (homePageStatus === status.idle || homePageStatus === status.loading) {
      return pageContentLoading();
    } else if (!bytesData) {
      return pageContentByteDataNull();
    } else {
      return pageContentSucceeded();
    }
  }

  return (
    <>
      {currentStatus === status.askConfirmation && (
        <ToastComponent
          headerText="Authorization required"
          message="You should be logged in to proceed"
          onClick={() => {
            setCurrentStatus(status.idle);
          }}
          toastType="yellow"
          actionButton={true}
          actionButtonText="Log In"
          actionButtonOnClick={() => {
            dispatch(setPath(window.location.pathname));
            navigate("/signIn");
          }}
        />
      )}
      {calculatePageContent()}
    </>
  );
}
