import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#2F60F5"
      d="M27.06 20.156a.937.937 0 0 1-.341 1.279l-11.25 6.562a.938.938 0 0 1-.945 0l-11.25-6.562a.938.938 0 0 1 .945-1.62L15 26.102l10.781-6.287a.938.938 0 0 1 1.279.341ZM25.78 14.19 15 20.477 4.219 14.19a.938.938 0 0 0-.938 1.62l11.25 6.562a.938.938 0 0 0 .945 0l11.25-6.562a.939.939 0 1 0-.945-1.62ZM2.813 9.375a.937.937 0 0 1 .468-.81l11.25-6.562a.938.938 0 0 1 .945 0l11.25 6.562a.938.938 0 0 1 0 1.62l-11.25 6.562a.938.938 0 0 1-.945 0l-11.25-6.562a.938.938 0 0 1-.469-.81Zm2.798 0L15 14.852l9.39-5.477L15 3.898 5.61 9.375Z"
    />
  </svg>
);
export default SvgComponent;
