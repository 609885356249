import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { status } from "enums/enums";
import { Course } from "types/data";


type MyCourses = {
  data: Course[];
  pages: number; 
}
type State = {
  myCourses: {
    pages:string;
    data: MyCourses[];
  };
  status: string; 
  error: string | null;
  currentPage: string;
};
const initialState : State = {
  myCourses: {
    data: [],
    pages: "",
  },
  status: status.idle,
  error: null,
  currentPage: "1",
};

export const fetchMyCourses = createAsyncThunk(
  "myCourse/getCourses",
  async ({ page }: { page: number }) => {
    try {
      const myCourses = await API.get("api", `courses/`, {
        queryStringParameters: {
          purchased: true,
          page,
        },
      });

      return myCourses;
    } catch (error) {
      return error;
    }
  }
);

export const uploadToMyCourses = createAsyncThunk(
  "myCourses/postCourse",
  async (id: string) => {
    try {
      await API.post("api", `/users/lessonSubmit/${id}/`, {});
    } catch (error) {
      return error;
    }
  }
);

export const byteCourseAnalytics = createAsyncThunk(
  "byte/course",
  async ({ id, body }: { id: string; body: any }) => { 
    console.log({body});
    
    try {
      await API.post("api", `/courses/analytics/`, {body});
    } catch (error) {
      throw error;
    }
  }
);

export const myLearningSlice = createSlice({
  name: "Mylearning",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMyCourses.pending, (state, action) => {
      state.status = status.loading;
    });

    builder.addCase(fetchMyCourses.fulfilled, (state, action) => {
      state.status = status.succeeded;
      state.myCourses = action.payload;
    });
  },
});

export default myLearningSlice.reducer;
