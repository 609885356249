import { useRef } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import CarouselRightArrow from "../../Assets/Svgs/CarouselRightArrow";
import CarouselLeftArrow from "../../Assets/Svgs/CarouselLeftArrow";

import CourseCard from "./CourseCardCarousel";
import styles from "./CourseCarousel.module.scss";
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
} from "react-icons/io";

function CourseCarousel({
  courses,
  categoryName,
  title,
  slug,
  setCurrentStatus,
}: any) {
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1536, min: 720 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  const slider = useRef<any>();

  function handleViewAllCourses() {
    if (slug === "Learning Bytes") {
      navigate({
        pathname: "/viewallbytes",
        search: createSearchParams({
          slug,
        }).toString(),
      });
      return;
    }
    navigate({
      pathname: "/viewallcourses",
      search: createSearchParams({
        slug,
      }).toString(),
    });
  }

  const ButtonGroup = () => {
    return (
      <div
        className="flex  mb-2 justify-between items-center px-2 mt-8"
        key={title}
      >
        <h5 className={styles.heading} style={{ height: "fitContent" }}>
          {title}
        </h5>

        <div className="flex flex-row items-center font-poppins font-semibold text-base text-[#004EC4]">
          <div
            className={styles.viewall}
            onClick={() => {
              handleViewAllCourses();
            }}
          >
            View All
          </div>
        </div>
      </div>
    );
  };

  let content;

  if (slug === "Learning Bytes") {
    content = courses.map((byte: any) => {
      return (
        <>
          <CourseCard
            course={byte}
            type="byte"
            setCurrentStatus={setCurrentStatus}
            key={byte.id}
          />
        </>
      );
    });
  } else {
    content = courses.map((course: any) => {
      return (
        <>
          <CourseCard
            course={course}
            key={course.id}
            categoryName={categoryName}
          />
        </>
      );
    });
  }

  return (
    <div className="pb-2 relative">
      <ButtonGroup />
      <div className="relative">
        <IoIosArrowDropleftCircle
          color="#AAB7CB"
          onClick={() => slider.current.previous()}
          className="hidden sm:block cursor-pointer absolute -left-[28px] top-1/2 transform -translate-y-1/2 z-10 w-8 h-8"
        />
        <Carousel
          responsive={responsive}
          infinite
          arrows={false}
          itemClass={styles.carouselitem}
          ref={slider}
          swipeable={true}
          minimumTouchDrag={30}
        >
          {content}
        </Carousel>
        <IoIosArrowDroprightCircle
          color="#AAB7CB"
          onClick={() => slider.current.next()}
          className="hidden sm:block cursor-pointer absolute -right-[28px] top-1/2 transform -translate-y-1/2  z-10 w-8 h-8"
        />
      </div>
    </div>
  );
}

export default CourseCarousel;
