export const maxCharsLength = (maxChars: number, inputString: string) => {
  return inputString?.length > maxChars
    ? `${inputString.substring(0, maxChars)}...`
    : inputString;
};

export const addTocartLocalStorage = (item: any, email: any) => {
  let cartStoredLocally;
  if (email) {
    cartStoredLocally = localStorage.getItem(`cart${email}`);
  } else {
    cartStoredLocally = localStorage.getItem("cart");
  }

  if (cartStoredLocally) {
    const parsedCart = JSON.parse(cartStoredLocally);

    const parser = parsedCart.findIndex((i: any) => {
      return i.id === item.id;
    });
    if (parser !== -1) return;
    parsedCart.push(item);
    if (email) {
      localStorage.setItem(`cart${email}`, JSON.stringify(parsedCart));
    } else {
      localStorage.setItem(`cart`, JSON.stringify(parsedCart));
    }

    return;
  }
  if (email) {
    localStorage.setItem(`cart${email}`, JSON.stringify([item]));
  } else {
    localStorage.setItem(`cart`, JSON.stringify([item]));
  }
};

export const deleteFromCartLocalStorage = (item: string, email: any) => {
  const localStorageItem = localStorage.getItem(`cart${email}`);

  if (!localStorageItem) return;
  const parsedCart = JSON.parse(localStorageItem);

  const cartItems = parsedCart.filter((element: any) => {
    return element.id !== item;
  });

  localStorage.setItem(`cart${email}`, JSON.stringify(cartItems));
};

export const removeAllFromCartLocalStorage = (email: any) => {
  localStorage.setItem(`cart${email}`, JSON.stringify([]));
};
