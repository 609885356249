import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { status } from "enums/enums";
import { AiFillAlipaySquare } from "react-icons/ai";
import { UserData } from "types/userInfo";

export const getUserInfo = createAsyncThunk("userInfo/get", async () => {
  try {
    const user = await API.get("api", "users/profile/", {});

    return user;
  } catch (error: any) {
    throw error;
  }
});

interface State {
  data: UserData;
  status: string;
  loading: boolean;
}

export const updateUserInfo = createAsyncThunk(
  "userInfo/update",
  async ({
    firstName,
    lastName,
    address,
    phone,
    email,
    profession,
    organisation,
    city,
    country,
    description,
    facebook,
    twitter,
    instagram,
    linkedin,
  }: {
    firstName: string;
    lastName: string;
    address: string;
    email: string;
    phone: string;
    profession: string;
    organisation: string;
    city: string;
    country: string;
    description: string;
    facebook: string;
    twitter: string;
    instagram: string;
    linkedin: string;
  }) => {
    try {
      await API.patch("api", "users/updateMyProfile/", {
        body: {
          firstName: firstName,
          lastName: lastName,
          address: address,
          phone: phone,
          email: email,
          profession: profession,
          organisation: organisation,
          city: city,
          country: country,
          description: description,
          facebook: facebook,
          twitter: twitter,
          instagram: instagram,
          linkedin: linkedin,
        },
      });
    } catch (error: any) {
      throw error.message;
    }
  }
);

export const infoSlice = createSlice({
  name: "userInfo",
  initialState: {
    data: {},
    status: status.idle,
    loading: false,
  } as State,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.status = status.succeeded;
      state.loading = false
    });
    builder.addCase(updateUserInfo.pending, (state, action) => {
      state.status = status.loading;
      state.loading = true
    });
    builder.addCase(updateUserInfo.fulfilled, (state, action) => {
      state.status = status.loading;
      state.loading = false
    });
  },
});
export default infoSlice.reducer;
