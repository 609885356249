import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify, Auth } from "aws-amplify";
import config from "./config";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";

import { v4 as uuidv4 } from "uuid";
import { PersistGate } from "redux-persist/integration/react";

const windowBaseUrl =
  window.location.protocol + "//" + window.location.host + "/";

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: "CUSTOM_AUTH",
  },
  oauth: {
    domain: "b2c-dev-server-learnsure.auth.ap-south-1.amazoncognito.com",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: `${windowBaseUrl}redirect`,
    redirectSignOut: `${windowBaseUrl}`,
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: new URL("/", config.apiGateway.URL).toString(),
        region: config.apiGateway.REGION,
        custom_header: async () => {
          let sessionId = localStorage.getItem("sessionId");
          if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem("sessionId", sessionId);
          }
          return { sessionId };
        },
      },
    ],
  },
  Storage: {
    region: "ap-south-1",
    bucket: "b2c-dev-learnsure-assets",
    identityPoolId: "ap-south-1:79caf221-c582-48e1-b61d-d3664519244d",
    customPrefix: {
      public: "",
      private: "",
    },
  },
});

(async function verify() {
  await Auth.currentCredentials();
})();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
