import TruncateMarkup from "react-truncate-markup";
import Rupee from "../../Assets/Svgs/Rupee";
import Time from "../../Assets/Svgs/Time";
import Time1 from "../../Assets/Svgs/Time1";
import Lessons from "../../Assets/Svgs/Lessons";

import { useNavigate, createSearchParams } from "react-router-dom";

import styles from "./CourseCarousel.module.scss";
import hover from "../../Styles/Partials/hover-animation.module.scss";
import { BiRupee } from "react-icons/bi";
import { status } from "enums/enums";
import { useAppDispatch, useAppSelector } from "Redux/store";
import { byteCourseAnalytics } from "Redux/Reducers/myLearningSlice";

function CourseCard({
  course,
  type,
  setCurrentStatus,
  categoryName,
}: {
  course: any;
  type?: any;
  setCurrentStatus?: Function;
  categoryName?: any;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  console.log("course", course);
  const isUserSignedIn = useAppSelector((state) => state.auth.isUserSignedIn);

  function handleCourseClick(slug: string, id: string, type: string) {
    dispatch(byteCourseAnalytics({ id: id, body: { type: type, id: id } }));
    navigate({
      pathname: "/course",
      search: createSearchParams({
        slug: course.courses.slug,
        id: course.courses.id,
        categoryName,
      }).toString(),
    });
  }

  function handleByteClick(id: string, type: string) {
    dispatch(byteCourseAnalytics({ id: id, body: { type: type, id: id } }));
    navigate({
      pathname: "/LearningBytes",
      search: createSearchParams({
        slug: course.slug,
      }).toString(),
    });
  }

  function calculateSalePrice() {
    if (course.courses.salePrice == "0") {
      return "Free";
    } else if (course.courses.salePrice == null) {
      return "Free";
    } else {
      return Math.round(course.courses.salePrice);
    }
  }

  function calculatePrice() {
    if (course.courses.price == "0") {
      return "";
    } else if (course.courses.price == null) {
      return "";
    } else {
      return (
        <div className={styles.mrp}>
          <div className="w-[110%] h-[1px] bg-[#888888] absolute mb-[18px]"></div>
          <div className="stroke-[#888888] h-full flex items-center justify-center">
            <BiRupee />
          </div>
          {Math.round(course.courses.price)}
        </div>
      );
    }
  }

  let content;

  if (type === "byte") {
    content = (
      <div
        key={course.id}
        className="cursor-pointer hover:translate-y-[-5px] duration-300 ease-in-out"
        onClick={() => {
          if (!isUserSignedIn) {
            setCurrentStatus && setCurrentStatus(status.askConfirmation);
            return;
          }
          handleByteClick(course.id, "byte");
        }}
      >
        <div className="flex flex-col h-fit w-full bg-white  rounded-2xl">
          <img
            src={course.thumbnailResource.metaData.compressed}
            alt="particular byte"
            className="h-[179.5px] md:h-[172px] lg:h-[152px] xl:h-[184px] object-cover transition ease-in-out rounded-2xl m-5"
            loading="lazy"
          />
          <div className="mx-5">
            <h1 className="font-poppins font-semibold text-lg h-[48px]  text-dark-blue">
              <TruncateMarkup lines={2}>
                <div>{course.title}</div>
              </TruncateMarkup>
            </h1>
            <div className="flex mb-5">
              <div className="text-[#4E4B66] font-poppins flex items-center">
                <Time1 />
                <p>{course.time} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div
        key={course.id}
        className={`cursor-pointer hover:translate-y-[-5px] duration-300 ease-in-out`}
        onClick={() => {
          handleCourseClick(course.courses.slug, course.id, "course");
        }}
      >
        <div className="flex flex-col h-fit w-full bg-white  rounded-2xl">
          <img
            src={course.resources?.metaData?.compressed}
            alt=""
            className=" h-[179.5px] md:h-[172px] lg:h-[152px] xl:h-[184px] object-cover transition ease-in-out rounded-2xl m-5"
            loading="lazy"
          />

          <div className="mx-5">
            <h1 className="font-poppins font-semibold text-lg h-[48px]  text-dark-blue">
              <TruncateMarkup lines={2}>
                <div>{course.courses?.title}</div>
              </TruncateMarkup>
            </h1>
            <div className="flex my-8">
              <div className="text-[#4E4B66] font-poppins flex items-center mr-12">
                <Lessons />
                <p className="ml-1">{course.lessonLength} Lessons</p>
              </div>
              <div className="text-[#4E4B66] font-poppins flex items-center">
                <Time1 />
                <p>{course.time} </p>
              </div>
            </div>
            <div className="mb-5">
              <div className="flex w-full justify-between">
                <div className="flex items-center">
                  <div className="flex items-start text-2xl">
                    <BiRupee />
                  </div>
                  <div className="flex items-center h-full">
                    <p className="font-poppins text-2xl font-semibold text-dark-blue  flex items-end h-full ">
                      {calculateSalePrice()}
                    </p>

                    {calculatePrice()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <>{content}</>;
}

export default CourseCard;
