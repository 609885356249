import { useAppSelector } from "Redux/store";
import Skeleton from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import styles from "../../Components/CourseCarousel/CourseCarousel.module.scss";

import HomePageByte from "Components/HomePageByte/HomePageByte";
import Sponsor from "../../Components/Sponsor/Sponsor";

export default function HomePageSkeleton() {
  const bytesData = useAppSelector((state) => state.homePage.bytes);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1300 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1300, min: 720 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  function renderCategories() {
    return (
      <>
        <div className="pb-2">
          <div className="flex  mb-2 justify-between items-center px-2">
            <h5 className={styles.heading} style={{ height: "fitContent" }}>
              <Skeleton width={200} />
            </h5>

            <div className="flex flex-row items-center ">
              <Skeleton width={200} />
            </div>
          </div>
          <Carousel
            responsive={responsive}
            infinite
            arrows={false}
            itemClass={styles.carouselitem}
            swipeable={true}
          >
            <Skeleton height={296} />

            <Skeleton height={296} />

            <Skeleton height={296} />

            <Skeleton height={296} />
          </Carousel>
        </div>
        <div className="pb-2">
          <div className="flex  mb-2 justify-between items-center px-2">
            <h5 className={styles.heading} style={{ height: "fitContent" }}>
              <Skeleton width={200} />
            </h5>

            <div className="flex flex-row items-center ">
              <Skeleton width={200} />
            </div>
          </div>
          <Carousel
            responsive={responsive}
            infinite
            arrows={false}
            itemClass={styles.carouselitem}
            swipeable={true}
          >
            <Skeleton height={296} />

            <Skeleton height={296} />

            <Skeleton height={296} />

            <Skeleton height={296} />
          </Carousel>
        </div>
        <div className="pb-2">
          <div className="flex  mb-2 justify-between items-center px-2">
            <h5 className={styles.heading} style={{ height: "fitContent" }}>
              <Skeleton width={200} />
            </h5>

            <div className="flex flex-row items-center ">
              <Skeleton width={200} />
            </div>
          </div>
          <Carousel
            responsive={responsive}
            infinite
            arrows={false}
            itemClass={styles.carouselitem}
            swipeable={true}
          >
            <Skeleton height={296} />

            <Skeleton height={296} />

            <Skeleton height={296} />

            <Skeleton height={296} />
          </Carousel>
        </div>
      </>
    );
  }
  return (
    <div className="sm:mx-[calc(50%-50vw)] bg-[#F6F9FC] ">
      <div className="container mx-auto">
        <div className="lg:grid lg:grid-cols-4 gap-4 mb-2 md:px-2 lg:mt-[60px] ">
          <div className="md:col-span-3">
            <HomePageByte />
          </div>
          <div>
            <Sponsor sponsor={bytesData} />
          </div>
        </div>
        <div>{renderCategories()}</div>
      </div>
    </div>
  );
}
