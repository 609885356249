import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { status } from "enums/enums";
import { ICourses } from "types";
import { Byte } from "types/byte";
import { Data } from "types/data";

export interface CourseData {
  title: string;
  slug: string;
  data: Data;
}
interface SpecificByte {
  byte: Byte | null;
  status: string;
  error: any;
}

interface LearningByte {
  bytes: Byte[];
  status: string;
  error: any;
  currentPage: string;
  pages: string;
  items: string;
}

interface State {
  courses: CourseData | null;
  bytes: Byte | null;
  status: string;
  error: any;
  specificByte: SpecificByte;
  learningByte: LearningByte;
  categories: Data[];
}

const initialState: State = {
  courses: null,
  bytes: null,
  status: status.idle,
  error: null,
  specificByte: {
    byte: null,
    status: status.idle,
    error: null,
  },
  learningByte: {
    bytes: [],
    status: status.idle,
    error: null,
    currentPage: "0",
    pages: "",
    items: "",
  },
  categories: [],
};

export const fetchHomePageData = createAsyncThunk("home/data", async () => {
  try {
    //fetches dynamic categories
    const requestCategories = await API.get("api", `courses/categories/`, {});
    //loops through categories and creates requests
    const requests = requestCategories.data.map((item: CourseData) => {
      return API.get("api", "courses/", {
        queryStringParameters: {
          category: item.slug,
        },
      });
    });
    //other requests pushed to request array so that all requests can be sent at once
    requests.push(
      API.get("api", `/courses/byte/todaysByte/`, {}),
      API.get("api", `courses/`, {
        queryStringParameters: {
          purchased: true,
        },
      }),
      API.get("api", `/courses/byte/`, {})
    );

    //All requests are sent
    const results = await Promise.all(requests);

    //breaks data and creates categorywisecourses slice
    const categoryWiseCourses = requestCategories.data.map(
      (item: any, index: number) => {
        return {
          title: item.title,
          slug: item.slug,
          data: results[index],
        };
      }
    );

    //breaks data(results) and assigns to appropriate slice
    const byteData = results[requestCategories.data.length];
    const myCourses = results[requestCategories.data.length + 1];
    const bytesData = results[requestCategories.data.length + 2];

    return { byteData, myCourses, bytesData, categoryWiseCourses };
  } catch (error: any) {
    window.location.href = "/RequestFailed";
  }
});

export const fetchParticularCategories = createAsyncThunk(
  "courses/sections",
  async ({ category, page }: { category: string; page: number | undefined }) => {
    console.log(category, page);
    try {
      const courses = await API.get("api", `courses/`, {
        queryStringParameters: {
          category,
          page,
        },
      });

      return { courses, page, category };
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchSpecificByte = createAsyncThunk(
  "byte/specificByte",
  async (slug: string) => {
    try {
      const byteData = await API.get("api", `/courses/byte/${slug}/`, {});
      return byteData;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchLearningByte = createAsyncThunk(
  "byte/learningByte",
  async (page: number) => {
    try {
      const bytesData = await API.get("api", `/courses/byte/`, {
        queryStringParameters: page,
      });

      return bytesData;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchDataSlice = createSlice({
  name: "Homepage",
  initialState,
  reducers: {
    clearSpecificByte: (state, action) => {
      state.specificByte.byte = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHomePageData.pending, (state, action) => {
      state.status = status.loading;
    });

    builder.addCase(fetchHomePageData.fulfilled, (state, action) => {
      state.status = status.succeeded;
      if (action.payload) {
        state.bytes = action.payload.byteData.data;
        state.categories = action.payload.categoryWiseCourses.map(
          (item: CourseData[], index: number) => {
            return { ...item, currentPage: "0" };
          }
        );
        state.learningByte.bytes = action.payload.bytesData.data;
        state.learningByte.items = action.payload.bytesData.items;
        state.learningByte.pages = action.payload.bytesData.pages;
      }
    });

    builder.addCase(fetchHomePageData.rejected, (state, action: any) => {
      state.status = status.failed;
      state.error = action.error.message;
    });

    builder.addCase(fetchSpecificByte.pending, (state, action) => {
      state.specificByte.status = status.loading;
    });
    builder.addCase(fetchSpecificByte.fulfilled, (state, action) => {
      state.specificByte.status = status.succeeded;
      state.specificByte.byte = action.payload.data;
    });

    builder.addCase(fetchLearningByte.pending, (state, action) => {
      state.learningByte.status = status.loading;
    });
    builder.addCase(fetchLearningByte.fulfilled, (state, action) => {
      state.learningByte.status = status.succeeded;

      state.learningByte.bytes = [
        ...state.learningByte.bytes,
        ...action.payload.data,
      ];
    });

    builder.addCase(fetchParticularCategories.fulfilled, (state, action) => {
      const currentCourseCategoryIndex = state.categories.findIndex((item) => {
        return item.slug === action.payload.category;
      });
      console.log("currentCourseCategoryIndex", currentCourseCategoryIndex);

      state.categories[currentCourseCategoryIndex].data.data = [
        ...state.categories[currentCourseCategoryIndex].data.data,
        ...action.payload.courses.data,
      ];

      state.categories[currentCourseCategoryIndex].currentPage =
        action.payload.page;
    });
  },
});

export const { clearSpecificByte } = fetchDataSlice.actions;
export default fetchDataSlice.reducer;
