import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Auth, Hub, API } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { status } from "enums/enums";

const initialState = {
  userSignedInStatus: status.idle,
  isUserSignedIn: false,
  user: null,
  loading: false,
  onBoarding: false,
  path:null
};

interface signUpInterface {
  email: string;
  phone: string;
  password: string;
}

export const signUp = createAsyncThunk(
  "auth/signup",
  async ({ email, phone, password }: signUpInterface) => {
    try {
      Hub.listen("auth", ({ payload }) => {
        const { event } = payload;
        if (event === "autoSignIn") {
          const user = payload.data;
          return user;
        } else if (event === "autoSignIn_failure") {
          throw { message: "autoSignIn_failure" };
        }
      });
      const res = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: phone,
        },
        autoSignIn: {
          enabled: true,
        },
      });
    } catch (error: any) {
      throw error.message;
    }
  }
);

export const updateUserInformation = createAsyncThunk(
  "auth/updateUserInformation",
  async ({
    profession,
    professionArea,
    professionBranch,
    firstName,
    lastName,
    location,
  }: {
    profession: string;
    professionArea: string;
    professionBranch: string;
    firstName: string;
    lastName: string;
    location: string;
  }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await API.post("api", "/auth/submitOnboardingDetails/", {
        body: {
          profession,
          professionArea,
          professionBranch,
          firstName,
          lastName,
          location,
        },
      });
      await Auth.updateUserAttributes(user, {
        "custom:onboarding_completed": "true",
      });
    } catch (error: any) {
      throw error.message;
    }
  }
);

export const isUserSignedIn = createAsyncThunk("auth/checkAuth", async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: false });

    return user.attributes;
  } catch (error: any) {
    throw error;
  }
});

export const facebookSignIn = createAsyncThunk(
  "auth/facebookSignIn",
  async () => {
    try {
      const user = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      });

      return user;
    } catch (error: any) {
      throw error;
    }
  }
);

export const googleSignIn = createAsyncThunk("auth/googleSignIn", async () => {
  try {
    const user = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
    return user;
  } catch (error: any) {
    throw error;
  }
});

export const confirmSignUp = createAsyncThunk(
  "auth/confirmSignUp",
  async (props: { email: string; code: string }) => {
    const { email, code } = props;
    try {
      const confirmSignUp = await Auth.confirmSignUp(email, code);
    } catch (error: any) {
      throw error.message;
    }
  }
);

export const resendSignUpConfirmationCode = createAsyncThunk(
  "auth/resendSignUpConfirmationCode",
  async (email: string) => {
    try {
      await Auth.resendSignUp(email);
    } catch (error: any) {
      throw error.message;
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (props: { email: string; password: string }) => {
    const { email, password } = props;
    try {
      const user = await Auth.signIn(email, password);
      return user;
    } catch (error: any) {
      throw error.message;
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  try {
    console.log("sign out triggered");
    await Auth.signOut();
  } catch (error: any) {
    throw error.message;
  }
});

//global signOut
export const globalSignOut = createAsyncThunk(
  "auth/globalSignOut",
  async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (error: any) {
      throw error.message;
    }
  }
);

//forgot password
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email: string) => {
    try {
      await Auth.forgotPassword(email);
    } catch (error: any) {
      throw error.message;
    }
  }
);

//Collect confirmation code and new password
export const forgotPasswordSubmit = createAsyncThunk(
  "auth/forgotPasswordSubmit",
  async (props: { username: string; code: string; password: string }) => {
    const { username, code, password } = props;
    try {
      const data = Auth.forgotPasswordSubmit(username, code, password);
      return data;
    } catch (error: any) {
      throw error.message;
    }
  }
);

//change Password
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (props: { oldPassword: string; newPassword: string }) => {
    const { oldPassword, newPassword } = props;
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then((data) => data);
    } catch (error: any) {
      throw error.message;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOnBoarding: (state, action: PayloadAction<boolean>) => {
      state.onBoarding = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(isUserSignedIn.fulfilled, (state, action) => {
      state.isUserSignedIn = true;
      state.user = action.payload;
      state.userSignedInStatus = status.succeeded;
    });
    builder.addCase(isUserSignedIn.rejected, (state, action) => {
      state.isUserSignedIn = false;
      state.userSignedInStatus = status.failed;
    });
    builder.addCase(isUserSignedIn.pending, (state, action) => {
      state.isUserSignedIn = false;
      state.userSignedInStatus = status.loading;
    });

    builder.addCase(googleSignIn.fulfilled, (state, action) => {
      state.isUserSignedIn = true;
      state.userSignedInStatus = status.succeeded;
    });

    // builder.addCase(signOut.fulfilled, (state, action) => {
    //   state.isUserSignedIn = false
    // })

    builder.addCase(signUp.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(confirmSignUp.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(confirmSignUp.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUserInformation.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUserInformation.pending, (state, action) => {
      state.loading = true;
    });
  },
});
export const { setOnBoarding } = authSlice.actions;
export default authSlice.reducer;
