import { API } from "aws-amplify";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  addTocartLocalStorage,
  removeAllFromCartLocalStorage,
} from "../../Utils/utils";
import { status } from "enums/enums";

const initialState = {
  cart: [],
  loading: true,
  checkoutLoading: false,
  currentState: status.idle,
};

export const purchaseCourses = createAsyncThunk(
  "Cart/purchase",
  async ({
    ids,
    couponCode,
    email,
  }: {
    ids: Array<number>;
    couponCode: string;
    email: string;
  }) => {
    try {
      const orderIdAndAmount = await API.post("api", "/payments/createOrder/", {
        body: { courses: ids,code:couponCode },
        couponCode,
      });
      const { orderId, amount } = orderIdAndAmount.data;
      let options = {
        key: "rzp_test_piuSz9Lsy1gVZY", // Enter the Key ID generated from the Dashboard
        amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Acme Corp",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response: any) {
          await API.post("api", "/payments/confirmPayment/", {
            body: {
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              signature: response.razorpay_signature,
            },
          });
          removeAllFromCartLocalStorage(email);
          removeAllFromCart();
          localStorage.removeItem("coupon");
          window.location.href = "/mycourses";
        },
        prefill: {
          name: "Gaurav Kumar",
          email: "gaurav.kumar@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      let rzp1 = new Razorpay(options);
      rzp1.open();
    } catch (error) {}
  }
);

export const addFromLocalStorageToCart = createAsyncThunk(
  "Cart/fromLocalStorage",
  async () => {
    let cartStoredLocally;
    const defaultCart = localStorage.getItem("cart");
    const defaultCartItem = defaultCart && JSON.parse(defaultCart);

    try {
      const user = await API.get("api", "users/profile/", {});

      if (defaultCartItem) {
        if (defaultCartItem.length > 0) {
          for (var i = 0; i < defaultCartItem.length; i++)
            addTocartLocalStorage(defaultCartItem[i], user.data.email);
          removeAllFromCartLocalStorage("");
        }
      }
      cartStoredLocally = localStorage.getItem(`cart${user.data.email}`);
    } catch {
      cartStoredLocally = localStorage.getItem(`cart`);
    }
    if (!cartStoredLocally) return [];
    const parsedCart = JSON.parse(cartStoredLocally);

    return parsedCart;
  }
);

export const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addTocart: (state, action: PayloadAction<any>) => {
      const isItemAlreadyInCart = state.cart.findIndex((item: any) => {
        return item.id === action.payload.id;
      });

      if (isItemAlreadyInCart !== -1) return;
      state.cart.push(action.payload);
    },

    deleteFromCart: (state, action) => {
      const id = action.payload;

      const cartItems = state.cart.filter((item: any) => {
        return item.id !== id;
      });
      state.cart = cartItems;
    },

    removeAllFromCart: (state) => {
      state.cart = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addFromLocalStorageToCart.fulfilled, (state, action) => {
      state.cart = action.payload;
      state.loading = false;
      state.currentState = status.succeeded;
    });
    builder.addCase(addFromLocalStorageToCart.pending, (state, action) => {
      state.cart = [];
      state.loading = true;
      state.currentState = status.loading;
    });
    builder.addCase(addFromLocalStorageToCart.rejected, (state, action) => {
      state.loading = false;
      state.currentState = status.failed;
    });
    builder.addCase(purchaseCourses.pending, (state, action) => {
      state.checkoutLoading = true;
    });
    builder.addCase(purchaseCourses.fulfilled, (state, action) => {
      // state.cart = [];
      state.checkoutLoading = false;
    });
  },
});

export const { addTocart, deleteFromCart, removeAllFromCart } =
  cartSlice.actions;
export default cartSlice.reducer;
