import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { status } from "enums/enums";

const initialState = {
  purchaseHistory: [],
  status: status.idle,
  error: null,
  //   currentPage: "1",
};

export const getPurchaseHistory = createAsyncThunk(
  "purchaseHistory/getPurchaseHistory",
  async () => {
    try {
      const purchaseHistory = await API.get(
        "api",
        "/payments/purchaseHistory/",
        {}
      );

      return purchaseHistory.data;
    } catch (error) {
      return error;
    }
  }
);

export const sendRefundRequest = createAsyncThunk(
  "purchaseHistory/postRefundRequest",
  async ({ courseId, reason }: { courseId: number; reason: string }) => {
    try {
      const PostRefundRequest = await API.post(
        "api",
        "/payments/refund/request/",
        { body: { courseId, reason } }
      );
      return PostRefundRequest;
    } catch (error) {
      return error;
    }
  }
);

export const purchaseHistory = createSlice({
  name: "purchaseHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPurchaseHistory.pending, (state, action) => {
      state.status = status.loading;
    });
    builder.addCase(getPurchaseHistory.fulfilled, (state, action) => {
      state.purchaseHistory = action.payload;
    });
  },
});

export default purchaseHistory.reducer;
