import styles from "./Sponsor.module.scss";
import TruncateMarkup from "react-truncate-markup";
import Skeleton from "react-loading-skeleton";
import Sponsor1 from "../../Assets/Images/sponsor1.png";
import Sponsor2 from "../../Assets/Images/sponsor2.png";

function AssignedMentorsCarousel({ sponsor }: any) {
  return sponsor ? (
    <>
      <div className="h-min lg:h-[332px] lg:mt-10 md:mt-4 my-2 hover:translate-y-[-5px] duration-300 ">
        <div className="h-full">
          <div
            className="relative bg-[#ECF0F9] rounded-xl border border-[#92B4FF]
             px-2 py-2 lg:flex-col lg:items-center 
                    lg:w-full lg:h-full lg:py-[24px] lg:px-[20px] my-4"
          >
            <h2 className="text-center font-poppins font-semibold text-[18px] text-dark-blue">
              {sponsor.sponsorCompanyName ? (
                "Today's learning byte is sponsored by"
              ) : (
                <Skeleton count={1.3} width={250} />
              )}
            </h2>
            <div className="flex justify-center">
              <div className="h-[150px] w-[150px]  my-2 ">
                {sponsor.sponsorCompanyName ? (
                  <img
                    src={sponsor.sponsorUrl}
                    alt="sponsor logo"
                    className="object-cover h-[150px] w-[150px]"
                  />
                ) : (
                  <Skeleton circle height={150} width={150} />
                )}
              </div>
            </div>
            <div>
              <div className="absolute bottom-[60px] left-10">
                <img src={Sponsor1} alt="sponsor illustrator" />
              </div>
              <div className="absolute right-5 bottom-5">
                <img src={Sponsor2} alt="sponsor illustrator" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="h-min lg:h-[332px] lg:mt-10 my-2 hover:translate-y-[-5px] duration-300">
        <div className="h-full">
          <div className="bg-white md:rounded-md flex justify-center items-center flex-col md:flex-row md:justify-center px-2 py-2 lg:flex-col lg:items-center lg:justify-between lg:w-full lg:h-full lg:py-[24px] lg:px-[20px] ">
            <h2 className="text-center font-poppins font-semibold text-[18px] text-dark-blue">
              Today's learning byte is sponsored by
            </h2>
            <div className="flex flex-col md:flex-row md:items-center lg:flex-col">
              <div className="rounded-full h-[150px] w-[150px] ml-3 mr-3 bg-[#F6F9FC] flex items-center justify-center">
                <img
                  src="https://d1z7xskuwdh467.cloudfront.net/ipi.png"
                  alt=""
                  className="object-cover rounded-full h-[150px] w-[150px]"
                />
              </div>
            </div>
            <div className="text-center font-poppins font-semibold text-[18px] text-dark-blue">iPi Global Solutions</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignedMentorsCarousel;
