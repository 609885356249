import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CourseCarousel from "Components/CourseCarousel/CourseCarousel";
import HomePageByte from "Components/HomePageByte/HomePageByte";
import Sponsor from "../../Components/Sponsor/Sponsor";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "../../Components/CourseCarousel/CourseCarousel.module.scss";
import { status } from "enums/enums";
import { motion } from "framer-motion";

import { useAppDispatch, useAppSelector } from "Redux/store";

import { fetchHomePageData } from "Redux/Reducers/fetchDataSlice";
import { fetchMyCourses } from "Redux/Reducers/myLearningSlice";
import { clearPath } from "Redux/Reducers/pathAndSlugPersist";
import { ToastComponent } from "Components/ToastComponent";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function HomePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1536, min: 720 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  const [loginStatus, setLoginStatus] = useState(status.idle);

  const bytesData = useAppSelector((state) => state.homePage.bytes);
  const learningBytes = useAppSelector((state) =>
    state.homePage.learningByte.bytes.slice(0, 8)
  );

  const categories = useAppSelector((state) => state.homePage.categories);

  const currentStatus = useAppSelector((state) => state.homePage.status);

  useEffect(() => {
    if (currentStatus === status.idle) {
      dispatch(fetchHomePageData());

      //We need to get all purchased courses in one request, so, that we can analyse which courses are purchased
      dispatch(fetchMyCourses({ page: 0 }));
    }
  }, [currentStatus, dispatch]);

  // useEffect(() => {
  //   dispatch(clearPath());
  // }, [dispatch]);

  function renderCategories() {
    if (categories.length) {
      return categories.map((item: any, index: number) => {
        return (
          <CourseCarousel
            categoryName={item.title}
            key={item.id}
            courses={item.data.data.slice(0, 8)}
            title={item.title}
            slug={item.slug}
          />
        );
      });
    } else {
      return (
        <>
          <div className="pb-2">
            <div className="flex  mb-2 justify-between items-center px-2">
              <h5 className={styles.heading} style={{ height: "fitContent" }}>
                <Skeleton width={200} />
              </h5>

              <div className="flex flex-row items-center ">
                <Skeleton width={200} />
              </div>
            </div>
            <Carousel
              responsive={responsive}
              infinite
              arrows={false}
              itemClass={styles.carouselitem}
              swipeable={true}
            >
              <Skeleton height={296} />

              <Skeleton height={296} />

              <Skeleton height={296} />

              <Skeleton height={296} />
            </Carousel>
          </div>
          <div className="pb-2">
            <div className="flex  mb-2 justify-between items-center px-2">
              <h5 className={styles.heading} style={{ height: "fitContent" }}>
                <Skeleton width={200} />
              </h5>

              <div className="flex flex-row items-center ">
                <Skeleton width={200} />
              </div>
            </div>
            <Carousel
              responsive={responsive}
              infinite
              arrows={false}
              itemClass={styles.carouselitem}
              swipeable={true}
            >
              <Skeleton height={296} />

              <Skeleton height={296} />

              <Skeleton height={296} />

              <Skeleton height={296} />
            </Carousel>
          </div>
          <div className="pb-2">
            <div className="flex  mb-2 justify-between items-center px-2">
              <h5 className={styles.heading} style={{ height: "fitContent" }}>
                <Skeleton width={200} />
              </h5>

              <div className="flex flex-row items-center ">
                <Skeleton width={200} />
              </div>
            </div>
            <Carousel
              responsive={responsive}
              infinite
              arrows={false}
              itemClass={styles.carouselitem}
              swipeable={true}
            >
              <Skeleton height={296} />

              <Skeleton height={296} />

              <Skeleton height={296} />

              <Skeleton height={296} />
            </Carousel>
          </div>
        </>
      );
    }
  }

  let content;

  content = (
    <div className="sm:mx-[calc(50%-50vw)] bg-[#F6F9FC]">
      <div className="container mx-auto">
        <div className="lg:grid lg:grid-cols-4 gap-4  md:px-2 ">
          <div className="md:col-span-3">
            <HomePageByte />
          </div>
          <div>
            <Sponsor sponsor={bytesData} />
          </div>
        </div>
        <div>
          {renderCategories()}
          <CourseCarousel
            courses={learningBytes}
            slug={"Learning Bytes"}
            title={"Learning Bytes"}
            setCurrentStatus={setLoginStatus}
          />
        </div>
      </div>
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {loginStatus === status.askConfirmation && (
        <ToastComponent
          message="You should be logged in to proceed"
          onClick={() => {
            setLoginStatus(status.idle);
          }}
          toastType="yellow"
          actionButton={true}
          actionButtonText="Log In"
          actionButtonOnClick={() => {
            navigate("/signIn");
          }}
        />
      )}
      {content}
    </motion.div>
  );
}
