import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#2F60F5"
      d="M22.937 24.6a4.538 4.538 0 0 1-4.25 2.9h-7.375a4.526 4.526 0 0 1-4.25-2.9 4.523 4.523 0 0 1 1.188-5.013L13.312 15H16.7l5.05 4.587a4.544 4.544 0 0 1 1.187 5.013Z"
      opacity={0.4}
    />
    <path
      fill="#2F60F5"
      d="M17.275 22.675h-4.55a.85.85 0 0 1 0-1.7h4.55a.85.85 0 0 1 .85.85c0 .463-.387.85-.85.85ZM22.937 5.4a4.538 4.538 0 0 0-4.25-2.9h-7.375a4.538 4.538 0 0 0-3.05 7.912L13.312 15H16.7l5.05-4.588A4.544 4.544 0 0 0 22.937 5.4Zm-5.662 3.637h-4.55a.85.85 0 0 1-.85-.85c0-.462.387-.85.85-.85h4.55a.85.85 0 0 1 .85.85c0 .463-.388.85-.85.85Z"
    />
  </svg>
);
export default SvgComponent;
