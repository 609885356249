import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth, API } from "aws-amplify";
import { status } from "enums/enums";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IModules, VideoCompletionData } from "types";


interface VideoCompletionState {
  videoCompletionData: VideoCompletionData | null; // Use the IModules type here
}

const initialState:VideoCompletionState = {
  videoCompletionData: null,
};

export const videoCompletionSlice = createSlice({
  name: "videoCompletionData",
  initialState,
  reducers: {
    addCourse: (state, action: PayloadAction<any>) => {
      state.videoCompletionData = action.payload;
    },
  },
});

export const { addCourse } = videoCompletionSlice.actions;
export default videoCompletionSlice.reducer;
